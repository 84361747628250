import { CRRegistration, CRRegistrationRegistrationSerializer } from "../_models/cr-registration"
import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { AlertService } from "./alert.service"
import { Router } from "@angular/router"
import { AppError } from "../_core/app-error"
import { CustomerRegistrationApiService } from "./api/customer-registration/customer-registration.api.service"
import { take } from "rxjs/operators"
import { LocalStorageService } from "./local-storage.service"
import { PricingTemplateTypeEnum } from "@app/enums/pricingTemplateTypeEnum"

@Injectable({
    providedIn: "root",
})
export class CustomerRegistrationService {
    public static readonly AUTH_HEADER_NAME = "V2-Customer-Registration-ID"
    private registrationHashSubject: BehaviorSubject<string>
    private quoteHashSubject: BehaviorSubject<string>

    constructor(
        private customerRegistrationApiService: CustomerRegistrationApiService,
        private alertService: AlertService,
        private router: Router,
        private cRRegistrationRegistrationSerializer: CRRegistrationRegistrationSerializer,
        private localStorageService: LocalStorageService,
    ) {
        this.registrationHashSubject = new BehaviorSubject<string>(
            this.localStorageService.getData("customerRegistrationHash"),
        )
        this.quoteHashSubject = new BehaviorSubject<string>(this.localStorageService.getData("customerQuoteHash"))
    }

    /**
     * Creates fresh customer registration.
     */
    createFresh(endpoint: string, partnerId: number, pricingTemplateTypeEnum: PricingTemplateTypeEnum): void {
        this.clear(endpoint)
        this.customerRegistrationApiService
            .createRecord(endpoint, partnerId, pricingTemplateTypeEnum)
            .pipe(take(1))
            .subscribe({
                next: (payload: CRRegistration) => {
                    payload.data.pricingTemplateType = pricingTemplateTypeEnum
                    this.setRecordHash(endpoint, payload.appRegistrationId)
                    this.setRecordToLocalStorage(endpoint, payload)

                    if (endpoint === "customer-registration") {
                        this.router.navigate([
                            "/customer-registration/" + payload.appRegistrationId + "/select-products",
                        ])
                    }
                    if (endpoint === "customer-quote") {
                        this.router.navigate(["/customer-quote/" + payload.appRegistrationId + "/select-products"])
                    }
                },
                error: (error: AppError) => {
                    this.alertService.userError(
                        error,
                        "We're sorry we are unable to create new registration",
                        false,
                        true,
                    )
                    throw error
                },
                complete: () => {
                    console.log("CR - Application successfully initialized!")
                },
            })
    }

    /**
     * Set registraion hash key
     *
     * @param registrationHash - string
     */
    setRecordHash(endpoint: string, registrationHash: string): void {
        if (endpoint === "customer-registration") {
            this.localStorageService.saveData("customerRegistrationHash", registrationHash)
            this.registrationHashSubject.next(registrationHash)
        }
        if (endpoint === "customer-quote") {
            this.localStorageService.saveData("customerQuoteHash", registrationHash)
            this.quoteHashSubject.next(registrationHash)
        }
    }

    /**
     * Returns local storage auth access token
     *
     * @returns
     */
    public getRecordHash(endpoint: string): string {
        let hash: string
        if (endpoint === "customer-registration") {
            hash = this.registrationHashSubject.value
        }
        if (endpoint === "customer-quote") {
            hash = this.quoteHashSubject.value
        }
        return hash ? hash : null
    }

    /**
     * Retrieve customer registraition from the local storage
     *
     * @returns CRRegistration
     */
    public getRecordFromLocalStorage(endpoint: string): CRRegistration {
        if (endpoint === "customer-registration") {
            const localCustomerRegistration = this.localStorageService.getData("customerRegistration")
            if (localCustomerRegistration !== null) {
                const customerRegistration = this.cRRegistrationRegistrationSerializer.fromJson(
                    JSON.parse(localCustomerRegistration),
                )
                return customerRegistration
            }
        }
        if (endpoint === "customer-quote") {
            const localCustomerQuote = this.localStorageService.getData("customerQuote")
            if (localCustomerQuote !== null) {
                const customerRegistration = this.cRRegistrationRegistrationSerializer.fromJson(
                    JSON.parse(localCustomerQuote),
                )
                return customerRegistration
            }
        }
    }

    /**
     * Set customer registraition to the local storage
     *
     */
    public setRecordToLocalStorage(endpoint: string, payload: CRRegistration): void {
        if (endpoint === "customer-registration") {
            const crr = this.cRRegistrationRegistrationSerializer.toJson(payload)
            const json = JSON.stringify(crr)
            this.localStorageService.saveData("customerRegistration", json)
        }
        if (endpoint === "customer-quote") {
            this.localStorageService.saveData(
                "customerQuote",
                JSON.stringify(this.cRRegistrationRegistrationSerializer.toJson(payload)),
            )
        }
    }

    /**
     * Clearing the registraion hash + registration
     */
    clear(endpoint: string): void {
        if (endpoint === "customer-registration") {
            this.localStorageService.removeData("customerRegistration")
            this.localStorageService.removeData("customerRegistrationHash")
            this.registrationHashSubject.next(null)
        }
        if (endpoint === "customer-quote") {
            this.localStorageService.removeData("customerQuote")
            this.localStorageService.removeData("customerQuoteHash")
            this.quoteHashSubject.next(null)
        }
    }
}
