import { AssociativeArray } from "./../../../_core/types"
import { PaginationSerializer } from "./../../../_models/pagination"
import { Injectable, Injector } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../../environments/environment"
import { CRRegistration, CRRegistrationRegistrationSerializer } from "../../../_models/cr-registration"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { VatRegistrationInfo, VatRegistrationInfoSerializer } from "@app/_models/vat-registration-info"
import { ApiResourceService } from "../api-resource.service"
import { PricingTemplateTypeEnum } from "@app/enums/pricingTemplateTypeEnum"

@Injectable({
    providedIn: "root",
})
export class CustomerRegistrationApiService extends ApiResourceService<CRRegistration> {
    requestSerializer: CRRegistrationRegistrationSerializer

    constructor(
        httpClient: HttpClient,
        requestSerializer: CRRegistrationRegistrationSerializer,
        protected paginationSerializer: PaginationSerializer,
        protected injector: Injector,
    ) {
        super(
            httpClient,
            environment.customerRegistrationApi.url.slice(0, -1),
            "",
            requestSerializer,
            paginationSerializer,
        )
    }

    public createRecord(
        endpoint: string,
        partnerId: number,
        pricingTemplateTypeEnum: PricingTemplateTypeEnum,
    ): Observable<CRRegistration> {
        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/" + endpoint
        return (
            this.httpClient
                .post<CRRegistration>(`${url}`, {
                    partner_id: partnerId,
                    seller_id: partnerId,
                    pricing_template_type: pricingTemplateTypeEnum,
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as CRRegistration))
        )
    }

    public getRecord(endpoint: string, registrationHash: string): Observable<CRRegistration> {
        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/" + endpoint + "/" + registrationHash

        return (
            this.httpClient
                .get<CRRegistration>(`${url}`)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as CRRegistration))
        )
    }

    public updateRecord(endpoint: string, registrationHash: string, item: CRRegistration): Observable<CRRegistration> {
        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/" + endpoint + "/" + registrationHash

        return (
            this.httpClient
                .put<CRRegistration>(`${url}`, this.serializer.toJson(item))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as CRRegistration))
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public calculateCardCommissions(data: any): Observable<CRRegistration> {
        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/card-rates/commissions-calculator"
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
        return this.httpClient.post<CRRegistration>(`${url}`, data).pipe(map((data: any) => data.data))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected convertData(data: any): CRRegistration[] {
        return data.map(item => this.serializer.fromJson(item))
    }

    public checkVATRegistration(values: AssociativeArray<string>): Observable<VatRegistrationInfo> {
        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/vat-registrations"

        const params = this.prepareHttpParams(values)
        const headers = this.prepareHttpHeaders({})
        const serializer = this.injector.get(VatRegistrationInfoSerializer)

        return (
            this.httpClient
                .get<VatRegistrationInfo>(`${url}`, { headers, params })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => serializer.fromJson(data.data) as VatRegistrationInfo))
        )
    }
}
